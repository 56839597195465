
html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.react-datepicker__input-container{
  margin-top: 10px;
}
.react-datepicker__input-container input{
  color: white;
  background-color: #2F3235;
  font-size: 15px;
  height: 40px;
  width: 50%;
  text-align: center;
  /* color: white;
  background-color: #2F3235;
  font-size: 10px;
  height: 40px; */
}
